import RULES from '@/assets/documents/Правила.pdf';
import ADULT from '@/assets/documents/Расписка для совершеннолетних.pdf';
import CHILD from '@/assets/documents/Расписка для несовершеннолетних.pdf';

const documents = {
  state: {
    documents: [
      {
        id: 1,
        file: RULES,
      },
      {
        id: 2,
        file: ADULT,
      },
      {
        id: 3,
        file: CHILD,
      },
    ],
  },
  getters: {},
  mutations: {},
  actions: {},
};

export default documents;
