<template>
  <section class="servicesEvents">
    <div class="servicesEvents__wrapper container">
      <div class="servicesEvents__titleBlock">
        <h3 class="servicesEvents__title">день рождения</h3>
        <h3 class="servicesEvents__title servicesEvents__title--grey">
          <svg
            v-if="screenWidth > 1686"
            width="40"
            height="39"
            viewBox="0 0 40 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.6305 0.8125L13.9982 7.44562L21.7694 15.2178L0.5 15.2178V24.5979L21.7694 24.5979L13.9982 32.3701L20.6305 39.0032L39.7235 19.9079L20.6305 0.8125Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth >= 1356 && screenWidth <= 1686"
            width="32"
            height="31"
            viewBox="0 0 32 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.5048 0.25L11.199 5.5565L17.4159 11.7742L0.400391 11.7742V19.2783L17.4159 19.2783L11.199 25.4961L16.5048 30.8026L31.7792 15.5263L16.5048 0.25Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth >= 640 && screenWidth <= 1355"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.25141 0.125L5.5985 2.77825L8.70697 5.88711L0.199219 5.8871V9.63917L8.70697 9.63917L5.5985 12.748L8.25141 15.4013L15.8886 7.76314L8.25141 0.125Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth <= 430"
            width="31"
            height="21"
            viewBox="0 0 31 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.9889 0L16.3511 3.63823L20.6136 7.90121L0 7.90121V13.0462L20.6136 13.0462L16.3511 17.3092L19.9889 20.9474L30.4613 10.4737L19.9889 0Z"
              fill="#E6E6E6"
            />
          </svg>
          с чемпионом
        </h3>
      </div>
      <div class="servicesEvents__cards">
        <div class="servicesEvents__cards-card servicesEvents__cards--blue">
          <span
            class="servicesEvents__cards-count servicesEvents__cards-count--blue"
            >1</span
          >
          <div class="servicesEvents__cards-description">
            <h2 class="servicesEvents__cards-title">
              Где отметить день рождения в москве?
            </h2>
            <p class="servicesEvents__cards-text">
              Проведи день рождения в любимом скейт-парке с одним из лучших
              райдеров России. В стоимость включено 2 часа аренды скейт-парка,
              показательное выступление от райдера и мастер-класс для гостей.
            </p>
          </div>
        </div>
        <video src="@/assets/img/emptyVideo.webp" controls width="550"></video>
        <div class="servicesEvents__cards-card servicesEvents__cards--black">
          <span class="servicesEvents__cards-count servicesEvents__cards-black"
            >2</span
          >
          <div class="servicesEvents__cards-description">
            <h2 class="servicesEvents__cards-title">
              Модный спортивный праздник
            </h2>
            <p class="servicesEvents__cards-text">
              Модный спортивный праздник для современных детей в формате
              мастер-класса с инструктором и программой подойдет девчонкам и
              мальчишкам 5 — 16 лет даже с нулевым уровнем катания
            </p>
          </div>
        </div>
        <img
          class="thumb servicesEvents__cards-thumb1"
          src="@/assets/img/thumbs2.svg"
          alt="thumb"
        />
        <img
          class="thumb servicesEvents__cards-thumb2"
          src="@/assets/img/thumbs2.svg"
          alt="thumb"
        />
        <img
          class="thumb servicesEvents__cards-thumb3"
          src="@/assets/img/thumbs2.svg"
          alt="thumb"
        />
        <img
          v-if="screenWidth <= 430"
          class="thumb servicesEvents__cards-thumb10"
          src="@/assets/img/thumbs2.svg"
          alt="thumb"
        />
      </div>

      <div class="servicesEvents__price" v-if="screenWidth > 640">
        <svg
          v-if="screenWidth > 1686"
          class="price-tippy"
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.62947 14.3252H10.4239V8.94197H8.62947V14.3252ZM9.52667 7.14758C9.78088 7.14758 9.99411 7.06145 10.1664 6.88918C10.3386 6.71692 10.4245 6.50399 10.4239 6.25038C10.4239 5.99617 10.3377 5.78294 10.1655 5.61068C9.99321 5.43841 9.78028 5.35258 9.52667 5.35318C9.27246 5.35318 9.05923 5.43931 8.88697 5.61157C8.71471 5.78383 8.62887 5.99677 8.62947 6.25038C8.62947 6.50458 8.7156 6.71782 8.88787 6.89008C9.06013 7.06234 9.27306 7.14817 9.52667 7.14758ZM9.52667 18.8112C8.28555 18.8112 7.11919 18.5755 6.0276 18.1042C4.93601 17.6328 3.98647 16.9937 3.17899 16.1869C2.37151 15.3794 1.73241 14.4298 1.26168 13.3382C0.79095 12.2467 0.555286 11.0803 0.554688 9.83917C0.554688 8.59805 0.790352 7.43169 1.26168 6.3401C1.73301 5.24851 2.37211 4.29897 3.17899 3.49149C3.98647 2.68401 4.93601 2.04491 6.0276 1.57418C7.11919 1.10345 8.28555 0.867786 9.52667 0.867188C10.7678 0.867188 11.9342 1.10285 13.0257 1.57418C14.1173 2.04551 15.0669 2.68461 15.8744 3.49149C16.6818 4.29897 17.3212 5.24851 17.7926 6.3401C18.2639 7.43169 18.4993 8.59805 18.4987 9.83917C18.4987 11.0803 18.263 12.2467 17.7917 13.3382C17.3203 14.4298 16.6812 15.3794 15.8744 16.1869C15.0669 16.9943 14.1173 17.6337 13.0257 18.1051C11.9342 18.5764 10.7678 18.8118 9.52667 18.8112Z"
            fill="#0091FF"
          />
        </svg>
        <svg
          v-if="screenWidth >= 640 && screenWidth <= 1686"
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.65179 6.12928H4.36955V3.97601H3.65179V6.12928ZM4.01067 3.25825C4.11235 3.25825 4.19764 3.2238 4.26655 3.15489C4.33545 3.08599 4.36979 3.00081 4.36955 2.89937C4.36955 2.79769 4.3351 2.71239 4.26619 2.64349C4.19729 2.57458 4.11211 2.54025 4.01067 2.54049C3.90899 2.54049 3.82369 2.57494 3.75479 2.64385C3.68588 2.71275 3.65155 2.79793 3.65179 2.89937C3.65179 3.00105 3.68624 3.08635 3.75515 3.15525C3.82405 3.22416 3.90923 3.25849 4.01067 3.25825ZM4.01067 7.92368C3.51422 7.92368 3.04768 7.82942 2.61104 7.64088C2.1744 7.45235 1.79459 7.19671 1.4716 6.87396C1.14861 6.55097 0.892964 6.17115 0.704672 5.73452C0.51638 5.29788 0.422114 4.83134 0.421875 4.33489C0.421875 3.83844 0.516141 3.37189 0.704672 2.93526C0.893203 2.49862 1.14885 2.11881 1.4716 1.79582C1.79459 1.47282 2.1744 1.21718 2.61104 1.02889C3.04768 0.840599 3.51422 0.746333 4.01067 0.746094C4.50712 0.746094 4.97366 0.840359 5.4103 1.02889C5.84693 1.21742 6.22675 1.47306 6.54974 1.79582C6.87273 2.11881 7.12849 2.49862 7.31702 2.93526C7.50556 3.37189 7.5997 3.83844 7.59946 4.33489C7.59946 4.83134 7.5052 5.29788 7.31667 5.73452C7.12813 6.17115 6.87249 6.55097 6.54974 6.87396C6.22675 7.19695 5.84693 7.45271 5.4103 7.64124C4.97366 7.82977 4.50712 7.92392 4.01067 7.92368Z"
            fill="#0091FF"
          />
        </svg>
        <span class="price-title">День рождения с чемпионом</span>
        <span class="price-duration">
          2 часа аренды парка, показательное шоу от райдера, мастер-класс для
          всех желающих
        </span>
        <span class="price-currentPrice">25 000 ₽</span>
        <button class="price-btn ms_booking">забронировать</button>
      </div>
    </div>

    <div class="servicesEvents__price container" v-if="screenWidth <= 430">
      <div class="servicesEvents__price-wrapper">
        <svg
          class="price-tippy"
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.62947 14.3252H10.4239V8.94197H8.62947V14.3252ZM9.52667 7.14758C9.78088 7.14758 9.99411 7.06145 10.1664 6.88918C10.3386 6.71692 10.4245 6.50399 10.4239 6.25038C10.4239 5.99617 10.3377 5.78294 10.1655 5.61068C9.99321 5.43841 9.78028 5.35258 9.52667 5.35318C9.27246 5.35318 9.05923 5.43931 8.88697 5.61157C8.71471 5.78383 8.62887 5.99677 8.62947 6.25038C8.62947 6.50458 8.7156 6.71782 8.88787 6.89008C9.06013 7.06234 9.27306 7.14817 9.52667 7.14758ZM9.52667 18.8112C8.28555 18.8112 7.11919 18.5755 6.0276 18.1042C4.93601 17.6328 3.98647 16.9937 3.17899 16.1869C2.37151 15.3794 1.73241 14.4298 1.26168 13.3382C0.79095 12.2467 0.555286 11.0803 0.554688 9.83917C0.554688 8.59805 0.790352 7.43169 1.26168 6.3401C1.73301 5.24851 2.37211 4.29897 3.17899 3.49149C3.98647 2.68401 4.93601 2.04491 6.0276 1.57418C7.11919 1.10345 8.28555 0.867786 9.52667 0.867188C10.7678 0.867188 11.9342 1.10285 13.0257 1.57418C14.1173 2.04551 15.0669 2.68461 15.8744 3.49149C16.6818 4.29897 17.3212 5.24851 17.7926 6.3401C18.2639 7.43169 18.4993 8.59805 18.4987 9.83917C18.4987 11.0803 18.263 12.2467 17.7917 13.3382C17.3203 14.4298 16.6812 15.3794 15.8744 16.1869C15.0669 16.9943 14.1173 17.6337 13.0257 18.1051C11.9342 18.5764 10.7678 18.8118 9.52667 18.8112Z"
            fill="#0091FF"
          />
        </svg>
        <div class="price-textBlock">
          <span class="price-title">День рождения с чемпионом</span>
          <span class="price-duration">
            2 часа аренды парка, показательное шоу от райдера, мастер-класс для
            всех желающих
          </span>
        </div>
        <span class="price-currentPrice">25 000 ₽</span>
      </div>
      <button class="price-btn ms_booking">забронировать</button>
    </div>
  </section>
  <LineComponent />
  <section class="servicesEvents">
    <div class="servicesEvents__wrapper container">
      <div class="servicesEvents__titleBlock">
        <h3 class="servicesEvents__title">спортивные</h3>
        <h3 class="servicesEvents__title servicesEvents__title--grey">
          <svg
            v-if="screenWidth > 1686"
            width="40"
            height="39"
            viewBox="0 0 40 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.6305 0.8125L13.9982 7.44562L21.7694 15.2178L0.5 15.2178V24.5979L21.7694 24.5979L13.9982 32.3701L20.6305 39.0032L39.7235 19.9079L20.6305 0.8125Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth >= 1356 && screenWidth <= 1686"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.5048 0.84375L11.199 6.15025L17.4159 12.368L0.400391 12.368V19.8721L17.4159 19.8721L11.199 26.0898L16.5048 31.3963L31.7792 16.12L16.5048 0.84375Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth >= 640 && screenWidth <= 1355"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.25141 0.125L5.5985 2.77825L8.70697 5.88711L0.199219 5.8871V9.63917L8.70697 9.63917L5.5985 12.748L8.25141 15.4013L15.8886 7.76314L8.25141 0.125Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth <= 430"
            width="31"
            height="21"
            viewBox="0 0 31 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.9889 0L16.3511 3.63823L20.6136 7.90121L0 7.90121V13.0462L20.6136 13.0462L16.3511 17.3092L19.9889 20.9474L30.4613 10.4737L19.9889 0Z"
              fill="#E6E6E6"
            />
          </svg>
          соревнования
        </h3>
      </div>
      <div class="servicesEvents__cards">
        <div class="servicesEvents__cards-card servicesEvents__cards--black">
          <span
            class="servicesEvents__cards-count servicesEvents__cards-count--black"
            >1</span
          >
          <div class="servicesEvents__cards-description">
            <h2
              class="servicesEvents__cards-title servicesEvents__cards-title--width"
            >
              масштабное спортивное событие в дисциплине самокат
            </h2>
            <p class="servicesEvents__cards-text">
              Фестивали, праздники, спортивные мероприятия и соревнования в
              любых категориях (новички, любители, профессионалы, женская и
              детско-юношеская лиги) и дисциплинах (стрит, парк, биг эир,
              памп-трек)
            </p>
          </div>
        </div>
        <div class="servicesEvents__cards-card servicesEvents__cards--blue">
          <span
            class="servicesEvents__cards-count servicesEvents__cards-count--blue"
            >2</span
          >
          <div class="servicesEvents__cards-description">
            <h2
              class="servicesEvents__cards-title servicesEvents__cards-title--width"
            >
              многолетний организационный опыт
            </h2>
            <p class="servicesEvents__cards-text">
              За более чем 12 лет работы наша команда организовала ярчайшие
              спортивные события в дисциплине самокат: чемпионат Russian Scooter
              Championship с общим охватом более 700 участников и 100000
              зрителей, Winter Scooter Games, Kick & Go, Scooter day и другие.
            </p>
          </div>
        </div>
        <video src="@/assets/img/emptyVideo.webp" controls width="550"></video>

        <img
          class="thumb servicesEvents__cards-thumb4"
          src="@/assets/img/thumbs2.svg"
          alt="thumb"
        />
        <img
          class="thumb servicesEvents__cards-thumb5"
          src="@/assets/img/thumbs2.svg"
          alt="thumb"
        />
        <img
          class="thumb servicesEvents__cards-thumb6"
          src="@/assets/img/thumbs2.svg"
          alt="thumb"
        />
        <img
          v-if="screenWidth <= 430"
          class="thumb servicesEvents__cards-thumb11"
          src="@/assets/img/thumbs2.svg"
          alt="thumb"
        />
      </div>

      <div class="servicesEvents__price" v-if="screenWidth > 640">
        <svg
          v-if="screenWidth > 1686"
          class="price-tippy"
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.62947 14.3252H10.4239V8.94197H8.62947V14.3252ZM9.52667 7.14758C9.78088 7.14758 9.99411 7.06145 10.1664 6.88918C10.3386 6.71692 10.4245 6.50399 10.4239 6.25038C10.4239 5.99617 10.3377 5.78294 10.1655 5.61068C9.99321 5.43841 9.78028 5.35258 9.52667 5.35318C9.27246 5.35318 9.05923 5.43931 8.88697 5.61157C8.71471 5.78383 8.62887 5.99677 8.62947 6.25038C8.62947 6.50458 8.7156 6.71782 8.88787 6.89008C9.06013 7.06234 9.27306 7.14817 9.52667 7.14758ZM9.52667 18.8112C8.28555 18.8112 7.11919 18.5755 6.0276 18.1042C4.93601 17.6328 3.98647 16.9937 3.17899 16.1869C2.37151 15.3794 1.73241 14.4298 1.26168 13.3382C0.79095 12.2467 0.555286 11.0803 0.554688 9.83917C0.554688 8.59805 0.790352 7.43169 1.26168 6.3401C1.73301 5.24851 2.37211 4.29897 3.17899 3.49149C3.98647 2.68401 4.93601 2.04491 6.0276 1.57418C7.11919 1.10345 8.28555 0.867786 9.52667 0.867188C10.7678 0.867188 11.9342 1.10285 13.0257 1.57418C14.1173 2.04551 15.0669 2.68461 15.8744 3.49149C16.6818 4.29897 17.3212 5.24851 17.7926 6.3401C18.2639 7.43169 18.4993 8.59805 18.4987 9.83917C18.4987 11.0803 18.263 12.2467 17.7917 13.3382C17.3203 14.4298 16.6812 15.3794 15.8744 16.1869C15.0669 16.9943 14.1173 17.6337 13.0257 18.1051C11.9342 18.5764 10.7678 18.8118 9.52667 18.8112Z"
            fill="#0091FF"
          />
        </svg>
        <svg
          v-if="screenWidth >= 640 && screenWidth <= 1686"
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.65179 6.12928H4.36955V3.97601H3.65179V6.12928ZM4.01067 3.25825C4.11235 3.25825 4.19764 3.2238 4.26655 3.15489C4.33545 3.08599 4.36979 3.00081 4.36955 2.89937C4.36955 2.79769 4.3351 2.71239 4.26619 2.64349C4.19729 2.57458 4.11211 2.54025 4.01067 2.54049C3.90899 2.54049 3.82369 2.57494 3.75479 2.64385C3.68588 2.71275 3.65155 2.79793 3.65179 2.89937C3.65179 3.00105 3.68624 3.08635 3.75515 3.15525C3.82405 3.22416 3.90923 3.25849 4.01067 3.25825ZM4.01067 7.92368C3.51422 7.92368 3.04768 7.82942 2.61104 7.64088C2.1744 7.45235 1.79459 7.19671 1.4716 6.87396C1.14861 6.55097 0.892964 6.17115 0.704672 5.73452C0.51638 5.29788 0.422114 4.83134 0.421875 4.33489C0.421875 3.83844 0.516141 3.37189 0.704672 2.93526C0.893203 2.49862 1.14885 2.11881 1.4716 1.79582C1.79459 1.47282 2.1744 1.21718 2.61104 1.02889C3.04768 0.840599 3.51422 0.746333 4.01067 0.746094C4.50712 0.746094 4.97366 0.840359 5.4103 1.02889C5.84693 1.21742 6.22675 1.47306 6.54974 1.79582C6.87273 2.11881 7.12849 2.49862 7.31702 2.93526C7.50556 3.37189 7.5997 3.83844 7.59946 4.33489C7.59946 4.83134 7.5052 5.29788 7.31667 5.73452C7.12813 6.17115 6.87249 6.55097 6.54974 6.87396C6.22675 7.19695 5.84693 7.45271 5.4103 7.64124C4.97366 7.82977 4.50712 7.92392 4.01067 7.92368Z"
            fill="#0091FF"
          />
        </svg>
        <span class="price-title">Спортивные соревнования</span>
        <span class="price-duration"
          >Организация спортивного мероприятия по запросу</span
        >
        <span class="price-currentPrice">цена договорная</span>
        <a href="tel:+88002505006" class="price-btn">8 (800) 250-50-06</a>
      </div>
      <div class="servicesEvents__price" v-if="screenWidth <= 430">
        <div class="servicesEvents__price-wrapper">
          <svg
            class="price-tippy"
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.62947 14.3252H10.4239V8.94197H8.62947V14.3252ZM9.52667 7.14758C9.78088 7.14758 9.99411 7.06145 10.1664 6.88918C10.3386 6.71692 10.4245 6.50399 10.4239 6.25038C10.4239 5.99617 10.3377 5.78294 10.1655 5.61068C9.99321 5.43841 9.78028 5.35258 9.52667 5.35318C9.27246 5.35318 9.05923 5.43931 8.88697 5.61157C8.71471 5.78383 8.62887 5.99677 8.62947 6.25038C8.62947 6.50458 8.7156 6.71782 8.88787 6.89008C9.06013 7.06234 9.27306 7.14817 9.52667 7.14758ZM9.52667 18.8112C8.28555 18.8112 7.11919 18.5755 6.0276 18.1042C4.93601 17.6328 3.98647 16.9937 3.17899 16.1869C2.37151 15.3794 1.73241 14.4298 1.26168 13.3382C0.79095 12.2467 0.555286 11.0803 0.554688 9.83917C0.554688 8.59805 0.790352 7.43169 1.26168 6.3401C1.73301 5.24851 2.37211 4.29897 3.17899 3.49149C3.98647 2.68401 4.93601 2.04491 6.0276 1.57418C7.11919 1.10345 8.28555 0.867786 9.52667 0.867188C10.7678 0.867188 11.9342 1.10285 13.0257 1.57418C14.1173 2.04551 15.0669 2.68461 15.8744 3.49149C16.6818 4.29897 17.3212 5.24851 17.7926 6.3401C18.2639 7.43169 18.4993 8.59805 18.4987 9.83917C18.4987 11.0803 18.263 12.2467 17.7917 13.3382C17.3203 14.4298 16.6812 15.3794 15.8744 16.1869C15.0669 16.9943 14.1173 17.6337 13.0257 18.1051C11.9342 18.5764 10.7678 18.8118 9.52667 18.8112Z"
              fill="#0091FF"
            />
          </svg>
          <div class="price-textBlock price-textBlock--width">
            <span class="price-title">Спортивные соревнования</span>
            <span class="price-duration"
              >Организация спортивного мероприятия по запросу</span
            >
          </div>
        </div>
        <a href="tel:+88002505006" class="price-btn">8 (800) 250-50-06</a>
      </div>
    </div>
  </section>
  <LineComponent />
  <section class="servicesEvents">
    <div class="servicesEvents__wrapper container">
      <div class="servicesEvents__titleBlock">
        <h3 class="servicesEvents__title">развлекательная</h3>
        <h3 class="servicesEvents__title servicesEvents__title--grey">
          <svg
            v-if="screenWidth > 1686"
            width="179"
            height="39"
            viewBox="0 0 179 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M159.13 0.8125L152.498 7.44562L160.269 15.2178L0 15.2178V24.5979L160.269 24.5979L152.498 32.3701L159.13 39.0032L178.224 19.9079L159.13 0.8125Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth >= 1356 && screenWidth <= 1686"
            width="143"
            height="32"
            viewBox="0 0 143 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M127.304 0.84375L121.999 6.15025L128.215 12.368L0 12.368V19.8721L128.215 19.8721L121.999 26.0898L127.304 31.3963L142.579 16.12L127.304 0.84375Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth >= 640 && screenWidth <= 1355"
            width="72"
            height="17"
            viewBox="0 0 72 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M63.6522 0.925781L60.9993 3.57903L64.1077 6.68789L0 6.68789V10.44L64.1077 10.44L60.9993 13.5488L63.6522 16.2021L71.2894 8.56392L63.6522 0.925781Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth <= 430"
            width="31"
            height="21"
            viewBox="0 0 31 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.9889 0L16.3511 3.63823L20.6136 7.90121L0 7.90121V13.0462L20.6136 13.0462L16.3511 17.3092L19.9889 20.9474L30.4613 10.4737L19.9889 0Z"
              fill="#E6E6E6"
            />
          </svg>
          программа
        </h3>
      </div>
      <div class="servicesEvents__cards">
        <video src="@/assets/img/emptyVideo.webp" controls width="550"></video>
        <div class="servicesEvents__cards-card servicesEvents__cards--blue">
          <span
            class="servicesEvents__cards-count servicesEvents__cards-count--blue"
            >1</span
          >
          <div class="servicesEvents__cards-description">
            <h2 class="servicesEvents__cards-title">
              репертуар команды kss team
            </h2>
            <p class="servicesEvents__cards-text">
              Спортивное развлекательное шоу с разнообразными постановочными,
              трюковыми номерами, мастер-классами и интерактивами со зрителями.
            </p>
          </div>
        </div>

        <div class="servicesEvents__cards-card servicesEvents__cards--black">
          <span class="servicesEvents__cards-count servicesEvents__cards-black"
            >2</span
          >
          <div class="servicesEvents__cards-description">
            <h2 class="servicesEvents__cards-title">Уникальная программа</h2>
            <p class="servicesEvents__cards-text">
              Спортивная шоу-программа состоит из: одного большого
              постановочного номера под музыку (15-20 мин.), интерактива со
              зрителями, в котором Ваша компания может разыграть призы для
              победителей (30 мин.)
            </p>
          </div>
        </div>
        <img
          class="thumb servicesEvents__cards-thumb7"
          src="@/assets/img/thumbs2.svg"
          alt="thumb"
        />
        <img
          class="thumb servicesEvents__cards-thumb8"
          src="@/assets/img/thumbs2.svg"
          alt="thumb"
        />
        <img
          class="thumb servicesEvents__cards-thumb9"
          src="@/assets/img/thumbs2.svg"
          alt="thumb"
        />
        <img
          v-if="screenWidth <= 430"
          class="thumb servicesEvents__cards-thumb12"
          src="@/assets/img/thumbs2.svg"
          alt="thumb"
        />
      </div>

      <div class="servicesEvents__price" v-if="screenWidth > 640">
        <svg
          v-if="screenWidth > 1686"
          class="price-tippy"
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.62947 14.3252H10.4239V8.94197H8.62947V14.3252ZM9.52667 7.14758C9.78088 7.14758 9.99411 7.06145 10.1664 6.88918C10.3386 6.71692 10.4245 6.50399 10.4239 6.25038C10.4239 5.99617 10.3377 5.78294 10.1655 5.61068C9.99321 5.43841 9.78028 5.35258 9.52667 5.35318C9.27246 5.35318 9.05923 5.43931 8.88697 5.61157C8.71471 5.78383 8.62887 5.99677 8.62947 6.25038C8.62947 6.50458 8.7156 6.71782 8.88787 6.89008C9.06013 7.06234 9.27306 7.14817 9.52667 7.14758ZM9.52667 18.8112C8.28555 18.8112 7.11919 18.5755 6.0276 18.1042C4.93601 17.6328 3.98647 16.9937 3.17899 16.1869C2.37151 15.3794 1.73241 14.4298 1.26168 13.3382C0.79095 12.2467 0.555286 11.0803 0.554688 9.83917C0.554688 8.59805 0.790352 7.43169 1.26168 6.3401C1.73301 5.24851 2.37211 4.29897 3.17899 3.49149C3.98647 2.68401 4.93601 2.04491 6.0276 1.57418C7.11919 1.10345 8.28555 0.867786 9.52667 0.867188C10.7678 0.867188 11.9342 1.10285 13.0257 1.57418C14.1173 2.04551 15.0669 2.68461 15.8744 3.49149C16.6818 4.29897 17.3212 5.24851 17.7926 6.3401C18.2639 7.43169 18.4993 8.59805 18.4987 9.83917C18.4987 11.0803 18.263 12.2467 17.7917 13.3382C17.3203 14.4298 16.6812 15.3794 15.8744 16.1869C15.0669 16.9943 14.1173 17.6337 13.0257 18.1051C11.9342 18.5764 10.7678 18.8118 9.52667 18.8112Z"
            fill="#0091FF"
          />
        </svg>
        <svg
          v-if="screenWidth >= 640 && screenWidth <= 1686"
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.65179 6.12928H4.36955V3.97601H3.65179V6.12928ZM4.01067 3.25825C4.11235 3.25825 4.19764 3.2238 4.26655 3.15489C4.33545 3.08599 4.36979 3.00081 4.36955 2.89937C4.36955 2.79769 4.3351 2.71239 4.26619 2.64349C4.19729 2.57458 4.11211 2.54025 4.01067 2.54049C3.90899 2.54049 3.82369 2.57494 3.75479 2.64385C3.68588 2.71275 3.65155 2.79793 3.65179 2.89937C3.65179 3.00105 3.68624 3.08635 3.75515 3.15525C3.82405 3.22416 3.90923 3.25849 4.01067 3.25825ZM4.01067 7.92368C3.51422 7.92368 3.04768 7.82942 2.61104 7.64088C2.1744 7.45235 1.79459 7.19671 1.4716 6.87396C1.14861 6.55097 0.892964 6.17115 0.704672 5.73452C0.51638 5.29788 0.422114 4.83134 0.421875 4.33489C0.421875 3.83844 0.516141 3.37189 0.704672 2.93526C0.893203 2.49862 1.14885 2.11881 1.4716 1.79582C1.79459 1.47282 2.1744 1.21718 2.61104 1.02889C3.04768 0.840599 3.51422 0.746333 4.01067 0.746094C4.50712 0.746094 4.97366 0.840359 5.4103 1.02889C5.84693 1.21742 6.22675 1.47306 6.54974 1.79582C6.87273 2.11881 7.12849 2.49862 7.31702 2.93526C7.50556 3.37189 7.5997 3.83844 7.59946 4.33489C7.59946 4.83134 7.5052 5.29788 7.31667 5.73452C7.12813 6.17115 6.87249 6.55097 6.54974 6.87396C6.22675 7.19695 5.84693 7.45271 5.4103 7.64124C4.97366 7.82977 4.50712 7.92392 4.01067 7.92368Z"
            fill="#0091FF"
          />
        </svg>
        <span class="price-title">Развлекательная программа</span>
        <span class="price-duration"
          >Показательное шоу райдеров команды KSS</span
        >
        <span class="price-currentPrice">цена договорная</span>
        <a href="tel:+88002505006" class="price-btn">8 (800) 250-50-06</a>
      </div>
      <div class="servicesEvents__price" v-if="screenWidth <= 430">
        <div class="servicesEvents__price-wrapper">
          <svg
            class="price-tippy"
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.62947 14.3252H10.4239V8.94197H8.62947V14.3252ZM9.52667 7.14758C9.78088 7.14758 9.99411 7.06145 10.1664 6.88918C10.3386 6.71692 10.4245 6.50399 10.4239 6.25038C10.4239 5.99617 10.3377 5.78294 10.1655 5.61068C9.99321 5.43841 9.78028 5.35258 9.52667 5.35318C9.27246 5.35318 9.05923 5.43931 8.88697 5.61157C8.71471 5.78383 8.62887 5.99677 8.62947 6.25038C8.62947 6.50458 8.7156 6.71782 8.88787 6.89008C9.06013 7.06234 9.27306 7.14817 9.52667 7.14758ZM9.52667 18.8112C8.28555 18.8112 7.11919 18.5755 6.0276 18.1042C4.93601 17.6328 3.98647 16.9937 3.17899 16.1869C2.37151 15.3794 1.73241 14.4298 1.26168 13.3382C0.79095 12.2467 0.555286 11.0803 0.554688 9.83917C0.554688 8.59805 0.790352 7.43169 1.26168 6.3401C1.73301 5.24851 2.37211 4.29897 3.17899 3.49149C3.98647 2.68401 4.93601 2.04491 6.0276 1.57418C7.11919 1.10345 8.28555 0.867786 9.52667 0.867188C10.7678 0.867188 11.9342 1.10285 13.0257 1.57418C14.1173 2.04551 15.0669 2.68461 15.8744 3.49149C16.6818 4.29897 17.3212 5.24851 17.7926 6.3401C18.2639 7.43169 18.4993 8.59805 18.4987 9.83917C18.4987 11.0803 18.263 12.2467 17.7917 13.3382C17.3203 14.4298 16.6812 15.3794 15.8744 16.1869C15.0669 16.9943 14.1173 17.6337 13.0257 18.1051C11.9342 18.5764 10.7678 18.8118 9.52667 18.8112Z"
              fill="#0091FF"
            />
          </svg>
          <div class="price-textBlock price-textBlock--width">
            <span class="price-title">Развлекательная программа</span>
            <span class="price-duration"
              >Показательное шоу райдеров команды KSS</span
            >
          </div>
        </div>
        <a href="tel:+88002505006" class="price-btn">8 (800) 250-50-06</a>
      </div>
    </div>
  </section>
  <LineComponent />
  <AdditionalComponent :additional="additional" :screenWidth="screenWidth" />

  <LineComponent v-if="screenWidth <= 430" />
  <AboutParkRulesComponent
    v-if="screenWidth <= 430"
    :screenWidth="screenWidth"
  />

  <LineComponent />
  <section class="feedback">
    <div class="feedback__wrapper container">
      <div class="feedback__titleBlock">
        <h3 class="feedback__title">Отзывы</h3>
        <h3 class="feedback__title feedback__title--grey">
          <svg
            v-if="screenWidth > 1686"
            xmlns="http://www.w3.org/2000/svg"
            width="57"
            height="39"
            fill="none"
          >
            <path
              fill="#E6E6E6"
              d="m37.13.814-6.632 6.634 7.771 7.772H0v9.38h38.27l-7.772 7.772 6.632 6.633L56.224 19.91 37.13.814Z"
            />
          </svg>
          <svg
            v-if="screenWidth >= 640 && screenWidth <= 1686"
            width="23"
            height="16"
            viewBox="0 0 23 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.8522 0.324219L12.1993 2.97747L15.3078 6.08632L0 6.08632V9.83839L15.3078 9.83839L12.1993 12.9472L14.8522 15.6005L22.4894 7.96236L14.8522 0.324219Z"
              fill="#E6E6E6"
            />
          </svg>
          учеников и родителей
        </h3>
      </div>
    </div>
    <FeedbackSliderComponent :screenWidth="screenWidth" />
  </section>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import AdditionalComponent from "@/components/AdditionalComponent.vue";
import LineComponent from "@/components/LineComponent.vue";
import FeedbackSliderComponent from "@/components/FeedbackSliderComponent.vue";
import AboutParkRulesComponent from "@/components/AboutParkRulesComponent.vue";

export default {
  components: {
    AdditionalComponent,
    LineComponent,
    FeedbackSliderComponent,
    AboutParkRulesComponent,
  },
  props: {
    screenWidth: Number,
  },

  computed: {
    ...mapState({
      additional: (state) => state.price.additionalEvent,
    }),
  },

  mounted() {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `https://w388672.yclients.com/widgetJS`;
    script.charset = "UTF-8";
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);
  },

  methods: {},
};
</script>

<style lang="scss" scoped></style>
