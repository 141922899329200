<template>
    <table>
        <thead>
            <tr>
                <td></td>
                <th>группа 1</th>
                <th>группа 2</th>
                <th>группа 3</th>
                <th>группа 4</th>
                <th>группа 5</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th>ПН</th>
                <td
                    class="groupSchedule__freeSkating"
                    v-on:mouseover="cellHover(0, 1)"
                    v-on:mouseleave="cellLeave(0, 1)"
                >
                    <button class="ms_booking groupSchedule__groupBtn">
                        {{ cells[0][1].freeSkating }}
                    </button>
                </td>
                <td
                    class="groupSchedule__freeSkating"
                    v-on:mouseover="cellHover(0, 2)"
                    v-on:mouseleave="cellLeave(0, 2)"
                >
                    <button class="ms_booking groupSchedule__groupBtn">
                        {{ cells[0][2].freeSkating }}
                    </button>
                </td>
                <td
                    class="groupSchedule__freeSkating"
                    v-on:mouseover="cellHover(0, 3)"
                    v-on:mouseleave="cellLeave(0, 3)"
                >
                    <button class="ms_booking groupSchedule__groupBtn">
                        {{ cells[0][3].freeSkating }}
                    </button>
                </td>
                <td
                    class="groupSchedule__freeSkating"
                    v-on:mouseover="cellHover(0, 4)"
                    v-on:mouseleave="cellLeave(0, 4)"
                >
                    <button class="ms_booking groupSchedule__groupBtn">
                        {{ cells[0][4].freeSkating }}
                    </button>
                </td>
                <td
                    class="groupSchedule__freeSkating"
                    v-on:mouseover="cellHover(0, 5)"
                    v-on:mouseleave="cellLeave(0, 5)"
                >
                    <button class="ms_booking groupSchedule__groupBtn">
                        {{ cells[0][5].freeSkating }}
                    </button>
                </td>
            </tr>
            <tr>
                <th>ВТ</th>
                <td>16:00 - 18:00</td>
                <td>18:00 - 20:00</td>
                <td
                    class="groupSchedule__freeSkating"
                    v-on:mouseover="cellHover(0, 6)"
                    v-on:mouseleave="cellLeave(0, 6)"
                >
                    <button class="ms_booking groupSchedule__groupBtn">
                        {{ cells[0][6].freeSkating }}
                    </button>
                </td>
                <td
                    class="groupSchedule__freeSkating"
                    v-on:mouseover="cellHover(0, 7)"
                    v-on:mouseleave="cellLeave(0, 7)"
                >
                    <button class="ms_booking groupSchedule__groupBtn">
                        {{ cells[0][7].freeSkating }}
                    </button>
                </td>
                <td
                    class="groupSchedule__freeSkating"
                    v-on:mouseover="cellHover(0, 8)"
                    v-on:mouseleave="cellLeave(0, 8)"
                >
                    <button class="ms_booking groupSchedule__groupBtn">
                        {{ cells[0][8].freeSkating }}
                    </button>
                </td>
            </tr>
            <tr>
                <th>СР</th>
                <td
                    class="groupSchedule__freeSkating"
                    v-on:mouseover="cellHover(0, 9)"
                    v-on:mouseleave="cellLeave(0, 9)"
                >
                    <button class="ms_booking groupSchedule__groupBtn">
                        {{ cells[0][9].freeSkating }}
                    </button>
                </td>
                <td
                    class="groupSchedule__freeSkating"
                    v-on:mouseover="cellHover(0, 10)"
                    v-on:mouseleave="cellLeave(0, 10)"
                >
                    <button class="ms_booking groupSchedule__groupBtn">
                        {{ cells[0][10].freeSkating }}
                    </button>
                </td>
                <td
                    class="groupSchedule__freeSkating"
                    v-on:mouseover="cellHover(0, 11)"
                    v-on:mouseleave="cellLeave(0, 11)"
                >
                    <button class="ms_booking groupSchedule__groupBtn">
                        {{ cells[0][11].freeSkating }}
                    </button>
                </td>
                <td
                    class="groupSchedule__freeSkating"
                    v-on:mouseover="cellHover(0, 12)"
                    v-on:mouseleave="cellLeave(0, 12)"
                >
                    <button class="ms_booking groupSchedule__groupBtn">
                        {{ cells[0][12].freeSkating }}
                    </button>
                </td>
                <td
                    class="groupSchedule__freeSkating"
                    v-on:mouseover="cellHover(0, 13)"
                    v-on:mouseleave="cellLeave(0, 13)"
                >
                    <button class="ms_booking groupSchedule__groupBtn">
                        {{ cells[0][13].freeSkating }}
                    </button>
                </td>
            </tr>
            <tr>
                <th>ЧТ</th>
                <td>16:00 - 18:00</td>
                <td>18:00 - 20:00</td>
                <td
                    class="groupSchedule__freeSkating"
                    v-on:mouseover="cellHover(0, 14)"
                    v-on:mouseleave="cellLeave(0, 14)"
                >
                    <button class="ms_booking groupSchedule__groupBtn">
                        {{ cells[0][14].freeSkating }}
                    </button>
                </td>
                <td
                    class="groupSchedule__freeSkating"
                    v-on:mouseover="cellHover(0, 15)"
                    v-on:mouseleave="cellLeave(0, 15)"
                >
                    <button class="ms_booking groupSchedule__groupBtn">
                        {{ cells[0][15].freeSkating }}
                    </button>
                </td>
                <td
                    class="groupSchedule__freeSkating"
                    v-on:mouseover="cellHover(0, 16)"
                    v-on:mouseleave="cellLeave(0, 16)"
                >
                    <button class="ms_booking groupSchedule__groupBtn">
                        {{ cells[0][16].freeSkating }}
                    </button>
                </td>
            </tr>
            <tr>
                <th>ПТ</th>
                <td
                    class="groupSchedule__freeSkating"
                    v-on:mouseover="cellHover(0, 17)"
                    v-on:mouseleave="cellLeave(0, 17)"
                >
                    <button class="ms_booking groupSchedule__groupBtn">
                        {{ cells[0][17].freeSkating }}
                    </button>
                </td>
                <td
                    class="groupSchedule__freeSkating"
                    v-on:mouseover="cellHover(0, 18)"
                    v-on:mouseleave="cellLeave(0, 18)"
                >
                    <button class="ms_booking groupSchedule__groupBtn">
                        {{ cells[0][18].freeSkating }}
                    </button>
                </td>
                <td
                    class="groupSchedule__freeSkating"
                    v-on:mouseover="cellHover(0, 19)"
                    v-on:mouseleave="cellLeave(0, 19)"
                >
                    <button class="ms_booking groupSchedule__groupBtn">
                        {{ cells[0][19].freeSkating }}
                    </button>
                </td>
                <td
                    class="groupSchedule__freeSkating"
                    v-on:mouseover="cellHover(0, 20)"
                    v-on:mouseleave="cellLeave(0, 20)"
                >
                    <button class="ms_booking groupSchedule__groupBtn">
                        {{ cells[0][20].freeSkating }}
                    </button>
                </td>
                <td
                    class="groupSchedule__freeSkating"
                    v-on:mouseover="cellHover(0, 21)"
                    v-on:mouseleave="cellLeave(0, 21)"
                >
                    <button class="ms_booking groupSchedule__groupBtn">
                        {{ cells[0][21].freeSkating }}
                    </button>
                </td>
            </tr>
            <tr>
                <th>СБ</th>
                <td>10:00 - 12:00</td>
                <td>12:00 - 14:00</td>
                <td>14:00 - 16:00</td>
                <td>16:00 - 18:00</td>
                <td class="groupSchedule__unavailable">18:00 - 20:00</td>
            </tr>
            <tr>
                <th>ВС</th>
                <td>10:00 - 12:00</td>
                <td>12:00 - 14:00</td>
                <td class="groupSchedule__unavailable">14:00 - 16:00</td>
                <td
                    class="groupSchedule__freeSkating"
                    v-on:mouseover="cellHover(0, 22)"
                    v-on:mouseleave="cellLeave(0, 22)"
                >
                    <button class="ms_booking groupSchedule__groupBtn">
                        {{ cells[0][22].freeSkating }}
                    </button>
                </td>
                <td
                    class="groupSchedule__freeSkating"
                    v-on:mouseover="cellHover(0, 23)"
                    v-on:mouseleave="cellLeave(0, 23)"
                >
                    <button class="ms_booking groupSchedule__groupBtn">
                        {{ cells[0][23].freeSkating }}
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    data() {
        return {
            cells: [
                [
                    { freeSkating: '' },
                    { freeSkating: '' },
                    { freeSkating: '' },
                    { freeSkating: '' },
                    { freeSkating: '' },
                    { freeSkating: '' },
                    { freeSkating: '' },
                    { freeSkating: '' },
                    { freeSkating: '' },
                    { freeSkating: '' },
                    { freeSkating: '' },
                    { freeSkating: '' },
                    { freeSkating: '' },
                    { freeSkating: '' },
                    { freeSkating: '' },
                    { freeSkating: '' },
                    { freeSkating: '' },
                    { freeSkating: '' },
                    { freeSkating: '' },
                    { freeSkating: '' },
                    { freeSkating: '' },
                    { freeSkating: '' },
                    { freeSkating: '' },
                    { freeSkating: '' },
                    { freeSkating: '' },
                    { freeSkating: '' },
                    { freeSkating: '' },
                    { freeSkating: '' },
                ],
            ],
        };
    },

    mounted() {
        this.styleTd();

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://w388672.yclients.com/widgetJS`;
        script.charset = 'UTF-8';
        script.crossOrigin = 'anonymous';
        document.body.appendChild(script);
    },
    methods: {
        styleTd() {
            const td = document.querySelectorAll('td');
            td.forEach((el) => {
                if (el.textContent !== '' && el.textContent !== '') {
                    el.style.background = '#0091FF';
                    el.style.color = '#fff';
                    el.style.fontSize = '18px';
                }
            });
        },

        cellHover(row, col) {
            this.cells[row][col].freeSkating = 'ИНДИВИД. ТРЕНИРОВКА';
        },
        cellLeave(row, col) {
            this.cells[row][col].freeSkating = '';
        },
    },
};
</script>

<style lang="scss" scoped>
table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 2px;
    margin-bottom: 30px;
    thead {
        td {
            background: #f6f6f6;
            width: 25px;
            text-align: center;
        }

        th {
            background: #252c37;
            width: 55px;
            height: 50px;
            text-align: center;
            vertical-align: middle;
            color: #fff;
            font-size: 12px;
            font-style: italic;
            font-weight: 800;
            line-height: 13px;
            text-transform: uppercase;
        }
    }

    tbody {
        th {
            background: #252c37;
            width: 25px;
            height: 50px;
            padding: 0 4px;
            color: #fff;
            text-align: center;
            vertical-align: middle;
            font-size: 12px;
            font-style: italic;
            font-weight: 800;
            line-height: 13px;
            text-transform: uppercase;
        }

        td {
            background: #f6f6f6;
            color: #d1d1d1;
            text-align: center;
            vertical-align: middle;
            width: 55px;
            height: 50px;
            font-size: 12px;
            font-weight: 600;
            line-height: 17px;
            text-transform: uppercase;
            cursor: pointer;

            &:hover {
                .groupSchedule__groupBtn {
                    color: #fff;
                }

                background: #252c37;
            }
        }
    }

    .individualSchedule__btn {
        border: none;
        outline: none;
        background: none;
        cursor: pointer;
        color: #fff;
    }
    .groupSchedule__groupBtn {
        border: none;
        outline: none;
        background: none;
        cursor: pointer;
        color: #d1d1d1;
    }
    .groupSchedule__unavailable {
        background: #676767 !important;
    }
}
</style>
