<template>
  <div
    v-if="downSlides.length > 0"
    v-for="(slide, index) in downSlides"
    :key="slide.id"
  >
  <a 
        :href="slide.link || '#'" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        <img
          :src="`https://samokat-school.chickenkiller.com/images/DownBanner/${slide.img}`"
          alt="Banner"
          class="championBanner container"
        />
      </a>
    
  </div>
  <div v-else>
    <p>Loading banner...</p>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { mapState } from "vuex";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },

  computed: {
    ...mapState({
      downSlides: (state) => state.downBanner.downSlides,
    }),
  },

  mounted() {
    // Загружаем данные
    this.$store.dispatch("fetchDownSlides").then(() => {
      console.log("Down slides loaded:", this.downSlides);
    });
  },
  watch: {
    downSlides(newValue) {
      console.log("Updated downSlides:", newValue);
    },
  },
};
</script>

<!-- <style lang="scss" scoped></style> -->
<!-- <style lang="scss" scoped>
/* Стиль для изображения */
@media (max-width: 1686px) and (min-width: 1356px) {

}
.championBanner {
  max-height: 400px;
  width: 90%;
  height: 20vw; 
  object-fit: cover;
  margin: 0 auto; /* Центрирование изображения */
  padding: 50px 0;
}
</style> -->
