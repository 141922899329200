import axiosInstance from "@/services/axiosInstance";
// import axios from "axios";

const downBanner = {
  state: {
    downSlides: [], 
  },
  getters: {},
  mutations: {
    SET_DOWNBANNER(state, data) {
      state.downSlides = data;
    },
  },
  actions: {
    async fetchDownSlides({ commit }) {
      try {
        const response = await axiosInstance("/downbanner");
        console.log(response.data, 'response.data');
        
        commit("SET_DOWNBANNER", response.data);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    },
  },
};

export default downBanner;
