<template>
  <section v-if="isCoachLoaded" class="CoachPage">
    <div class="CoachPage__wrapper container">
      <div class="CoachPage__titleBlock">
        <div class="CoachPage__mobileWrapper">
          <h1 class="CoachPage__title">
            {{ coach.name }}
            <svg v-if="screenWidth > 1686" width="177" height="87" fill="none">
              <path
                fill="#E6E6E6"
                d="m134.046.823-14.82 14.822 17.365 17.367H.119v20.96h136.472l-17.365 17.366 14.82 14.822 42.663-42.668L134.046.823Z"
              />
            </svg>
            <svg
              v-if="screenWidth >= 1356 && screenWidth <= 1686"
              width="142"
              height="69"
              fill="none"
            >
              <path
                d="M107.638 0.453125L95.7818 12.3105L109.674 26.2039L0.496094 26.2039V42.9718L109.674 42.9718L95.7818 56.8652L107.638 68.7226L141.768 34.5879L107.638 0.453125Z"
                fill="#E6E6E6"
              />
            </svg>
            <svg
              v-if="screenWidth >= 640 && screenWidth <= 1355"
              width="71"
              height="35"
              fill="none"
            >
              <path
                d="M53.8188 0.726562L47.8909 6.65523L54.8368 13.6019L0.248047 13.6019V21.9859L54.8368 21.9859L47.8909 28.9326L53.8188 34.8613L70.8841 17.7939L53.8188 0.726562Z"
                fill="#E6E6E6"
              />
            </svg>
            <svg v-if="screenWidth <= 430" width="61.355" height="29.787" fill="none">
              <path
                fill="#E6E6E6"
                d="M46.464 0 41.29 5.174l6.06 6.061H0v7.317h47.352l-6.061 6.061 5.173 5.174 14.891-14.893L46.464 0Z"
              />
            </svg>
          </h1>
          <h2 class="CoachPage__title CoachPage__title--grey">
            {{ coach.lastName }}
          </h2>
        </div>
        <!-- <img
          class="CoachPage__avatar"
          v-if="screenWidth <= 430"
          :src="`http://localhost:3000/images/Coaches/${coach.avatar}`"
          alt="avatar"
        /> -->
        <img
          class="CoachPage__avatar"
          v-if="screenWidth <= 430"
          :src="`https://samokat-school.chickenkiller.com/images/Coaches/${coach.avatar}`"
          alt="avatar"
        />
      </div>
      <div class="CoachPage__singUp">
        <p class="CoachPage__singUp-text">
          {{ coach.description }}
        </p>
        <button 
          class="CoachPage__singUp-btn ms_booking" 
          :data-url="coach.bookingUrl"
          v-if="coach.bookingUrl"
        >
          ЗАПИСАТЬСЯ НА ТРЕНИРОВКУ
        </button>
      </div>
    </div>
  </section>

  <!-- <img
    v-if="screenWidth > 640"
    class="CoachPage__mainIMG"
    :src="`http://localhost:3000/images/Coaches/${coach.photo}`"
    alt="Akimov"
  /> -->
  <img
    v-if="screenWidth > 640"
    class="CoachPage__mainIMG"
    :src="`https://samokat-school.chickenkiller.com/images/Coaches/${coach.photo}`"
    alt="Akimov"
  />
  <!-- <img
    v-if="screenWidth <= 430"
    class="CoachPage__mainIMG"
    :src="`http://localhost:3000/images/Coaches/${coach.photoMobile}`"
    alt="Akimov"
  /> -->
  <img
    v-if="screenWidth <= 430"
    class="CoachPage__mainIMG"
    :src="`https://samokat-school.chickenkiller.com/images/Coaches/${coach.photoMobile}`"
    alt="Akimov"
  />
  <LineComponent v-if="screenWidth > 640" />

  <section class="CoachPage__description container">
    <div class="CoachPage__features-list">
      <div class="CoachPage__feature">
        <h2>ДОСТИЖЕНИЯ</h2>
        <p v-if="typeof coach.achievements === 'string'">
          {{ coach.achievements }}
        </p>
        <ul v-else v-for="(achive, index) in coach.Achievements" :key="index">
          <li>{{ achive.name }}</li>
        </ul>
      </div>
      <div class="CoachPage__feature">
        <h2>ДИСЦИПЛИНА</h2>
        <p>
          {{ coach.education }}
        </p>
      </div>
      <div class="CoachPage__feature">
        <h2>ОПЫТ</h2>
        <p>{{ coach.skills }}</p>
      </div>
      <div class="CoachPage__feature" v-if="coach.sponsors">
        <h2>СПОНСОРЫ</h2>
        <p>{{ coach.sponsors }}</p>
      </div>
    </div>
    <!-- <video
      class="CoachPage__video"
      :src="`http://localhost:3000/videos/coaches/${coach.video}`"
      controls
    ></video> -->
    <video
      class="CoachPage__video"
      :src="`https://samokat-school.chickenkiller.com/videos/coaches/${coach.video}`"
      controls
    ></video>
  </section>
  <LineComponent />
  <section class="CoachPage__slider" v-if="screenWidth > 640">
    <CoachSliderComponent :screenWidth="screenWidth" :coachesPhotos="coach.photos" />
  </section>

  <section class="CoachPage__slider" v-if="screenWidth <= 430">
    <div class="CoachPage__slider__wrapper container">
      <div class="CoachPage__slider__titleBlock">
        <h3 class="CoachPage__slider__title">Медиа</h3>
      </div>
    </div>
    <CoachSliderComponentMobile :screenWidth="screenWidth" :coachesPhotos="coach.photos" />
  </section>
  <LineComponent v-if="screenWidth <= 430" style="margin-bottom: 40px" />
</template>

<script>
import CoachSliderComponent from '@/components/CoachSliderComponent.vue';
import CoachSliderComponentMobile from '@/components/CoachSliderComponentMobile.vue';
import LineComponent from '@/components/LineComponent.vue';
import { mapState } from 'vuex';

export default {
  name: 'Coach Page',
  components: {
    LineComponent,
    CoachSliderComponent,
    CoachSliderComponentMobile,
  },
  props: {
    screenWidth: Number,
  },
  data() {
    return {
      isLoadingCoach: true,
    };
  },
  mounted() {
    this.$store
      .dispatch('fetchCoach')
      .then(() => {
        const currentCoach = this.coaches.find((el) => el.path === this.$route.path);
        if (currentCoach) {
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = `${currentCoach.idYclientsForm}`;
          script.charset = 'UTF-8';
          script.crossOrigin = 'anonymous';
          document.body.appendChild(script);
        } else {
          console.error('Такого тренера не существует');
        }
        this.isLoadingCoach = false;
      })
      .catch((error) => {
        console.error('Ошибка при загрузке тренера:', error);
        this.isLoadingCoach = false;
      });
  },
  computed: {
    ...mapState({
      coaches: (state) => state.coach.coaches,
    }),
    coach() {
      return this.coaches.find((coach) => coach.path === this.$route.path) || {};
    },
    isCoachLoaded() {
      return this.coaches.length > 0 && !this.isLoadingCoach;
    },
  },
};
</script>
