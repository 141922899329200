import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueTippy from 'vue-tippy';

import '@/assets/scss/main.scss';

import 'tippy.js/dist/tippy.css';
import YmapPlugin from 'vue-yandex-maps';
import MasonryWall from '@yeger/vue-masonry-wall';

const settings = {
  apiKey: '373a66c6-62f6-44fd-a381-8312e2465fbc', // Индивидуальный ключ API
  lang: 'ru_RU', // Используемый язык
  coordorder: 'latlong', // Порядок задания географических координат
  debug: false, // Режим отладки
  version: '2.1', // Версия Я.Карт
};

const app = createApp(App);

// Глобальная обработка ошибок
app.config.errorHandler = (err, vm, info) => {
  console.error('Vue Error:', err);
  console.error('Info:', info);
};

app.use(store);
app.use(router);
app.use(VueTippy);
app.use(YmapPlugin, settings);
app.use(MasonryWall);

app.mount('#app');
