import { createStore } from 'vuex';
import headerNavigation from './modules/headerNavigation';
import topBanner from './modules/topBanner';
import downBanner from './modules/downBanner';
import aboutScooterCards from './modules/aboutScooterCards';
import coaches from './modules/coaches';
import coach from './modules/coach';
import partners from './modules/partners';
import faq from './modules/faq';
import documents from './modules/documents';
import feedbackCards from './modules/feedbackCards';
import homeQuestions from './modules/homeQuestions';
import media from './modules/media';
import footerNavigation from './modules/footerNavigation';
import educationStepCards from './modules/educationStepCards';
import eventCards from './modules/eventCards';
import schemePark from './modules/schemePark';
import aboutParkPhotos from './modules/aboutParkPhotos';
import trainingVideo from './modules/trainingVideo';
import advantagesCards from './modules/advantagesCards';
import price from './modules/price';
import breadcrumbs from './modules/breadcrumbs';
import aboutSubjects from './modules/aboutSubjects';

export default createStore({
  state: {
    screenWidth: 0,
    isShowHoverRules: false,
  },
  getters: {},
  mutations: {
    updateScreenWidth(state, width) {
      state.screenWidth = width;
    },
    openHoverRules(state) {
      state.isShowHoverRules = !state.isShowHoverRules;
    },
  },
  actions: {
    updateWidth(context, width) {
      context.commit('updateScreenWidth', width);
    },
  },
  modules: {
    headerNavigation,
    topBanner,
    downBanner,
    aboutScooterCards,
    coaches,
    partners,
    faq,
    feedbackCards,
    homeQuestions,
    footerNavigation,
    educationStepCards,
    eventCards,
    schemePark,
    aboutParkPhotos,
    trainingVideo,
    coach,
    media,
    advantagesCards,
    price,
    breadcrumbs,
    aboutSubjects,
    documents,
  },
});
