<template>
  <swiper
    :navigation="{ prevEl: '.swiper-button-prev', nextEl: '.swiper-button-next' }"
    :modules="modules"
    :slidesPerView="screenWidth > 640 ? 3.5 : 1"
    :centeredSlides="false"
    :spaceBetween="15"
    :grabCursor="true"
    class="mySwiper feedback__swiper"
  >
    <swiper-slide v-for="card in feedbackState" :key="card.id">
      <article class="feedback__card">
        <div class="feedback__card-titleBlock">
          <div class="feedback__nameBlock">
            <h3 class="feedback__name">{{ card.name }}</h3>
            
            <svg
              v-if="screenWidth <= 1686 && screenWidth >= 1356"
              width="94"
              height="19"
              viewBox="0 0 94 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                v-for="(_, index) in Array.from({
                  length: Number(card.rating),
                })"
                :key="index"
                :transform="'translate(' + index * 20 + ', 0 )scale(2)'"
              >
                <path
                  d="M4.63978 0.472656L5.61877 3.48567H8.78684L6.22381 5.34782L7.2028 8.36084L4.63978 6.49869L2.07675 8.36084L3.05574 5.34782L0.492714 3.48567H3.66079L4.63978 0.472656Z"
                  fill="#252C37"
                />
              </g>
            </svg>
          
          <svg v-if="screenWidth <= 430" width="73" height="15" fill="none">
              <path
                fill="#252C37"
                d="m7.142.959 1.51 4.649h4.888L9.586 8.481l1.51 4.65-3.954-2.874-3.955 2.873 1.51-4.649L.744 5.608H5.63L7.141.959ZM21.722.959l1.51 4.649h4.889l-3.955 2.873 1.51 4.65-3.954-2.874-3.954 2.873 1.51-4.649-3.955-2.873h4.889l1.51-4.649ZM36.303.959l1.51 4.649h4.89l-3.956 2.873 1.51 4.65-3.954-2.874-3.954 2.873 1.51-4.649-3.955-2.873h4.889l1.51-4.649ZM50.884.959l1.51 4.649h4.89l-3.956 2.873 1.511 4.65-3.955-2.874-3.954 2.873 1.51-4.649-3.954-2.873h4.888l1.51-4.649ZM65.465.959l1.51 4.649h4.889l-3.955 2.873 1.51 4.65-3.954-2.874-3.955 2.873 1.511-4.649-3.955-2.873h4.889l1.51-4.649Z"
              />
            </svg>
          </div>
          <span class="feedback__date">{{ new Date(card.date).toLocaleDateString('en-CA') }}</span>
        </div>

        <p class="feedback__text">{{ card.text }}</p>
      </article>
    </swiper-slide>
    <div class="feedback__navigation">
      <div class="swiper-button-prev feedback__navigation-prev"></div>
      <div class="swiper-button-next feedback__navigation-next"></div>
    </div>
  </swiper>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

import { Navigation } from "swiper/modules";
import { mapState } from "vuex";

export default {
  props: {
    screenWidth: Number,
  },

  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },

  computed: {
    ...mapState({
      feedbackState: (state) => state.feedbackCards.feedbackState,
    }),
  },
  mounted() {
    // console.log(
    //   "Feedback from Vuex in component (initial):",
    //   this.feedbackState
    // );
    this.$store.dispatch("fetchFeedback").then(() => {
      // console.log("Feedbacks after fetching:", this.feedbackState);
    });
  },
  watch: {
    feedbackState(newValue) {
      // console.log("Updated feedbackState in component:", newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "swiper/css/navigation";
</style>
