<template>
    <table>
        <thead>
            <tr>
                <td>&nbsp;</td>
                <th>ПН</th>
                <th>ВТ</th>
                <th>СР</th>
                <th>ЧТ</th>
                <th>ПТ</th>
                <th>СБ</th>
                <th>ВС</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th>10:00</th>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td
                    rowspan="2"
                    v-on:mouseover="cellHover(0, 1)"
                    v-on:mouseleave="cellLeave(0, 1)"
                >
                    <button class="ms_booking individualSchedule__groupBtn">
                        {{ cells[0][1].groupTitle }}
                    </button>
                </td>
                <td
                    rowspan="2"
                    v-on:mouseover="cellHover(0, 2)"
                    v-on:mouseleave="cellLeave(0, 2)"
                >
                    <button class="ms_booking individualSchedule__groupBtn">
                        {{ cells[0][2].groupTitle }}
                    </button>
                </td>
            </tr>
            <tr>
                <th>11:00</th>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
            </tr>
            <tr>
                <th>12:00</th>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td
                    rowspan="2"
                    v-on:mouseover="cellHover(0, 3)"
                    v-on:mouseleave="cellLeave(0, 3)"
                >
                    <button class="ms_booking individualSchedule__groupBtn">
                        {{ cells[0][3].groupTitle }}
                    </button>
                </td>
                <td
                    rowspan="2"
                    v-on:mouseover="cellHover(0, 4)"
                    v-on:mouseleave="cellLeave(0, 4)"
                >
                    <button class="ms_booking individualSchedule__groupBtn">
                        {{ cells[0][4].groupTitle }}
                    </button>
                </td>
            </tr>
            <tr>
                <th>13:00</th>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
            </tr>
            <tr>
                <th>14:00</th>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td
                    rowspan="2"
                    v-on:mouseover="cellHover(0, 5)"
                    v-on:mouseleave="cellLeave(0, 5)"
                >
                    <button class="ms_booking individualSchedule__groupBtn">
                        {{ cells[0][5].groupTitle }}
                    </button>
                </td>
                <td
                    rowspan="2"
                    v-on:mouseover="cellHover(0, 6)"
                    v-on:mouseleave="cellLeave(0, 6)"
                >
                    <button class="ms_booking individualSchedule__groupBtn">
                        {{ cells[0][6].groupTitle }}
                    </button>
                </td>
            </tr>
            <tr>
                <th>15:00</th>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
            </tr>
            <tr>
                <th>16:00</th>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td
                    rowspan="2"
                    v-on:mouseover="cellHover(0, 7)"
                    v-on:mouseleave="cellLeave(0, 7)"
                >
                    <button class="ms_booking individualSchedule__groupBtn">
                        {{ cells[0][7].groupTitle }}
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td
                    rowspan="2"
                    v-on:mouseover="cellHover(0, 8)"
                    v-on:mouseleave="cellLeave(0, 8)"
                >
                    <button class="ms_booking individualSchedule__groupBtn">
                        {{ cells[0][8].groupTitle }}
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td
                    rowspan="2"
                    v-on:mouseover="cellHover(0, 9)"
                    v-on:mouseleave="cellLeave(0, 9)"
                >
                    <button class="ms_booking individualSchedule__groupBtn">
                        {{ cells[0][9].groupTitle }}
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
            </tr>
            <tr>
                <th>17:00</th>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>

                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>

                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>

                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
            </tr>
            <tr>
                <th>18:00</th>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td
                    rowspan="2"
                    v-on:mouseover="cellHover(0, 10)"
                    v-on:mouseleave="cellLeave(0, 10)"
                >
                    <button class="ms_booking individualSchedule__groupBtn">
                        {{ cells[0][10].groupTitle }}
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td
                    rowspan="2"
                    v-on:mouseover="cellHover(0, 11)"
                    v-on:mouseleave="cellLeave(0, 11)"
                >
                    <button class="ms_booking individualSchedule__groupBtn">
                        {{ cells[0][11].groupTitle }}
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td
                    rowspan="2"
                    v-on:mouseover="cellHover(0, 12)"
                    v-on:mouseleave="cellLeave(0, 12)"
                >
                    <button class="ms_booking individualSchedule__groupBtn">
                        {{ cells[0][12].groupTitle }}
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
            </tr>
            <tr>
                <th>19:00</th>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>

                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>

                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>

                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
            </tr>
            <tr>
                <th>20:00</th>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
                <td>
                    <button class="ms_booking individualSchedule__btn">
                        записаться
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    data() {
        return {
            cells: [
                [
                    { groupTitle: 'Групповая тренировка' },
                    { groupTitle: 'Групповая тренировка' },
                    { groupTitle: 'Групповая тренировка' },
                    { groupTitle: 'Групповая тренировка' },
                    { groupTitle: 'Групповая тренировка' },
                    { groupTitle: 'Групповая тренировка' },
                    { groupTitle: 'Групповая тренировка' },
                    { groupTitle: 'Групповая тренировка' },
                    { groupTitle: 'Групповая тренировка' },
                    { groupTitle: 'Групповая тренировка' },
                    { groupTitle: 'Групповая тренировка' },
                    { groupTitle: 'Групповая тренировка' },
                    { groupTitle: 'Групповая тренировка' },
                    { groupTitle: 'Групповая тренировка' },
                ],
            ],
        };
    },

    methods: {
        cellHover(row, col) {
            this.cells[row][col].groupTitle = 'Записаться';
        },
        cellLeave(row, col) {
            this.cells[row][col].groupTitle = 'Групповая тренировка';
        },
    },

    mounted() {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://w388672.yclients.com/widgetJS`;
        script.charset = 'UTF-8';
        script.crossOrigin = 'anonymous';
        document.body.appendChild(script);
    },
};
</script>

<style lang="scss" scoped>
table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 15px;
    thead {
        td {
            background: #f6f6f6;
            width: 9%;
            text-align: center;
        }

        th {
            background: #0091ff;
            width: 13%;
            text-align: center;
            color: #fff;
            font-size: 30px;
            font-style: italic;
            font-weight: 800;
            line-height: 30px;
            text-transform: uppercase;
            padding: 10px 0;
        }
    }

    tbody {
        th {
            background: #70c1ff;
            color: #fff;
            padding: 10px 0;
            text-align: center;
            font-size: 30px;
            font-style: italic;
            font-weight: 800;
            line-height: 30px;
            text-transform: uppercase;
        }

        td {
            background: #d1d1d1;
            color: #fff;
            padding: 13px 0;
            text-align: center;
            font-size: 23px;
            font-style: italic;
            font-weight: 600;
            line-height: 24px;
            text-transform: uppercase;
            transition: all 0.3s ease-in-out;
            cursor: pointer;

            &:hover {
                .individualSchedule__groupBtn {
                    color: #fff;
                }

                background: #252c37;
            }
        }

        td[rowspan] {
            padding: 0 10px;
            color: #d1d1d1;
            background: #f6f6f6;
            text-align: center;
            vertical-align: middle;
            transition: all 0.3s ease-in-out;
            cursor: pointer;

            &:hover {
                color: #fff;
                background: #252c37;
            }
        }
    }

    .individualSchedule__btn {
        border: none;
        outline: none;
        background: none;
        cursor: pointer;
        color: #fff;
        text-transform: uppercase;
    }
    .individualSchedule__groupBtn {
        border: none;
        outline: none;
        background: none;
        cursor: pointer;
        color: #d1d1d1;
        text-transform: uppercase;
    }
}
</style>
